// Set variables for typography
$body-font-family: "Montserrat";
$script-font-family: "Dancing Script";
$font-weight-normal: 400;
$font-weight-bold: 700;

html,
body {
  background: #eee;
}

#wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;

  // Image
  .kim-image {
    max-width: 180px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
  }

  // Typography
  h1 {
    font-family: $body-font-family;
    font-size: 1.8em;
    font-weight: $font-weight-normal;

    em {
      display: block;
      color: #ff69b4;
      font-family: $script-font-family;
      font-size: 1.8em;
    }
  }

  h2 {
    font-size: 1.3em;
    font-family: $body-font-family;
    font-weight: $font-weight-normal;
  }

  p {
    color: #111;
    font-size: 1.2em;
    line-height: 1.7;

    & > a {
      font-family: $body-font-family;
      font-size: 0.8em;
    }
  }

  // Contact and profile links
  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 20px;

    .link-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 180px;
      min-height: 180px;
      margin: 0 auto 15px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 180px;
        min-height: 180px;
        color: #111;
        font-family: $body-font-family;
        font-size: 1em;
        text-transform: uppercase;
        text-decoration: none;
        background-color: #ddd;
        border-radius: 100%;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        transform: translate(0, 0);
        transition: background-color 0.3s ease, box-shadow 0.3s ease,
          transform 0.3s ease;

        &:hover,
        &:focus {
          background-color: #dcdcdc;
          box-shadow: 0px 0px 0px transparent;
          transform: translate(2px, 2px);
        }
      }
    }
  }

  // Made With Love
  footer {
    padding: 40px 0 0;

    hr {
      border-top: none;
      border-bottom: 1px solid #bbb;
    }

    p {
      font-family: $script-font-family;
      font-size: 24px;

      // Heart
      span {
        display: inline-block;
        margin-left: 7px;
        font-size: 28px;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}

@media screen and (min-width: 667px) {
  #wrapper .links {
    flex-flow: row wrap;
    padding: 20px 0 0;

    .link-box {
      margin: 0 auto;
    }
  }
}
